import "../assets/css/styles_start.css";
export function Frases() {
  const frases = [
    "Hacen falta millones de estrellas para iluminar el universo, pero para iluminar mi corazón sobra una sonrisa tuya <3",
    "En la noche me puse a contar las estrellas y ni todas ellas alcanzaron para enumerar las razones por las cuales te amo",
    "En éste universo tan grande, desde las raíces mas profundas de la tierra, hasta las estrellas más lejanas te amaré",
    "En el cielo existen miles de estrellas bonitas y la que más me gusta eres tú",
    "Si me preguntaran que fue lo mejor que me pudo haber pasado respondería que: conocerte lo fue....",
    "Ni en mil galaxias podré encontrar una estrella tan bonita cómo tu<3",
    "Si todos pudieran ver tus ojos como yo los veo, ningún astronauta volvería a buscar las estrellas",
    "En el cielo hay mil estrellas, pero la que mas me llama la atención eres tu",
    "Mientras haya pájaros que canten, al rayar la aurora cada día, mientras que den perfume las gardenias, te amare vida mía",
    "Amate, nunca vuelvas a ese lugar que te hizo sentir una persona insegura, triste, molesta, mal amada; porque no es ahí donde perteneces",
    "Aún con esta linda noche, con el cielo estrellado yo sigo prefiriendo verte a ti, últimamente todo se trata de ti",
    "Si supieras lo que siento cada que te puedo ver... Que detesto este silencio de no poderte decir.... Amor en todo lo que pienso es en ti:(",
    "Si por besarte tuviera que ir al infierno, lo haría, así podré presumir a los demonios que estuve en el paraíso sin necesidad de entrar...",
    "Si tu belleza fuera un pecado, pelearía con el mismísimo lucifer solo para poder apreciar tu belleza una y otra vez",
    "Para que se necesito un telescopio, si puedo ver las estrellas en tus ojos<3",
    "De las 24 horas del día, 8 sueño contigo y 16 pienso en ti<3",
    "Te amaba el día de ayer, y hoy también. Siempre te he amado, y siempre te amare<3",
    "Tan bello es el sol, Tan bella es la luna, Y alguien cómo tu.. No la cambio por ninguna.",
    "No soy artista. pero prometo borrar las lágrimas de tus ojos para dibujarte una sonrisa en tus labios<3",
    "Yo solo quiero ser amado... Pero solo por ti y nadie más",
    "No sé si eres un sueño o una realidad, pero si estoy soñando, no me quiero despertar.",
    "Ni aunque todas las canciones se hayan escrito y todos los poemas que ya se hayan dicho, ninguno expresará lo dichoso que soy por amarte",
    "No tengo alas para llevarte al cielo... Pero  si tengo palabras para decirte te quiero<3",
    "Si el amarte es verte y el verte es perderte, prefiero.... amarte sin verte, que verte para perderte",
    "Me he puesto a pensar y el amor que siento por ti es mas grande que el mismo universo y más",
    '"Solo un tonto cambiaría algo tan único como el amor por algo tan común como el dinero"',
    "Tengo los ojos para mirarte, la boca para besarte, la vida para quererte y el corazón para amarte....<3",
    "Millones de personas en el mundo, y solo tú eres con la que quiero pasar el resto de mis días....",
    "Llegaste de la nada y te convertiste en mi todo",
    "Si el cielo fuera papel, y el mar tintero.... No alcanzaría a escribir lo mucho que te quiero<3",
    "Cuando pienso en ti, olvido los problemas de la vida<3",
    "Te amo desde el primer beso... hasta mi último suspiro<3",
    "Espero que nunca tengas que verme triunfar desde lejos... Porque sabes perfectamente que mi única intención desde que llegue a tu vida es triunfar estando juntos",
    "Hay tantas cosas bonitas que yo quisiera decirte y dedicarte no puedo dejar de pensar en ti en cada instante, eres lo más bonito e importante que tengo, quisiera pasar cada momento contigo, me haces muy feliz. Te amo demasiado<3",
    "Un día te vi, a partir de ese día, supe lo que quería hacer con mi vida<3",
    "Ojala te vieras así como yo te veo, así entenderías la razón por la que te amo tanto<3",
    "Y si quieres que me marche... Dímelo y me marcharé. Y si quieres darte un tiempo... Pídelo y te esperaré. Pero no juegues a lastimarme porque... Mi corazón sin dudarlo a ti siempre te lo entregaré...",
    "La verdad yo no se para donde voy, pero si es contigo, voy a donde sea, con los ojos vendados, por que cada día que estoy a tu lado se bien donde me quiero quedar.<3",
    "No, yo no te voy a dejar, no te voy a cambiar, no te voy a remplazar, no me voy a alejar de ti, creo que a veces no eres consciente de lo feliz que me haces y con un mensaje me alegras todos y cada uno de mis días, ni cuando mi corazón deje de latir... dejaré de amarte<3",
    "Tienes un borrador y una pluma? Es para borrar tu pasado y escribir nuestro futuro<3",
    "Para que mirar el cielo y las estrellas, si con tan solo mirar tus ojos veo cada una de ellas",
    "No te digo algo bonito... te digo algo sincero, mi cariño es infinito y mi amor es verdadero<3",
    "Yo contigo nunca jugaría a las escondidas porque una mujer como tú es difícil de encontrar<3",
    "En el cielo brilla el sol, las estrellas y la luna, y en mi corazón brillas tu cómo ninguna<3",
    "Te voy a querer hoy, mañana y hasta que se acabe el universo, eres la persona que llego a mi vida sin pensarlo, sin avisar, fue la mas linda coincidencia<3",
    "Tus ojos son arte, tu cuerpo poesía, y tus sentimientos mi filosofía<3",
    "Quiero quedarme contigo, quizás aún seamos muy jóvenes, quizás nos falte mucho por vivir, pero te lo digo ahora y te lo diré siempre, quiero quedarme contigo, no quiero otros amores, no quiero otra sonrisa más q la tuya, no quiero otros abrazos... Solamente te quiero a ti<3",
    "En el cielo hay una estrella que me llama la atención... y en la tierra... hay una persona que me roba el corazón<3",
    "Si la luna fuera mía te la regalaría.. pero cómo no es mía, solo me queda decirte que eres el amor de mi vida<3",
    "Hoy no sabía que ponerme, así que me puse a pensar en ti<3",
    "Un lápiz sin punta no puede escribir... y yo sin tu amor no puedo vivir<3",
    "No te digo algo bonito, te digo algo sincero, mi cariño es infinito y mi amor es verdadero<3",
    "Sabes por que el mar es salado? Porque tu tienes lo dulce<3",
    "A ti te veo en todos mis planes, incluso en lo que creí que no invitaría a nadie<3.",
    "Ahora entiendo no es que tuviera mala suerte en el amor, la vida estaba haciendo esperarte<3",
    "Tu retrato se conserva como una pintura intacta en el museo de mi mente<3",
    "No me preguntes por que, pero mi mente se pasa todo el día hablando de ti<3",
    "Ojalá, cada vez que te mires las manos sientas que te faltan las mías<3",
    "Ella cree que es imperfecta, muere por quemar sus defectos....Yo muero por ellos, vivo por ella, no conozco a alguien tan perfecta<3",
    "Me gusta la forma en que me tratas, la forma en que me miras, me gusta como me alegras el día, me gustan tus ojos, tu sonrisa, en fin... Me gusta todo de ti<3",
    "Por luna daría un beso, daría todo por el sol, pero por la luz de tu mirada doy mi vida y mi corazón...<3",
    "Eres más ardiente que el sol, Más linda que la luna y las estrellas, Por eso ellas te tienen envidia porque tú no eres la única cosa perfecta...<3",
    "Unos quieren la luna, otros quieren el sol, pero yo solo quiero un espacio en tu corazón....<3",
    "Napoleón con una espada conquistó una nación y tú con tu mirada conquistaste mi corazón...<3",
    "Una rosa es una flor, un tesoro es una fortuna. Y una chica como tú no la cambio por ninguna...<3",
    "Del cielo cayó una estrella y de la estrella una luz, que suerte la mía al saber que eras tu...<3",
    "Si el río suena es porque piedras está trayendo. Si mi corazón late es porque mis ojos te ven sonriendo... <3",
    "Yo soy el sol, tu eres la luna. Y a ti voy a amarte como ninguna...<3",
    "De todas la definiciones que existen de amor, tu eres mi favorita<3",
    "Ni el mejor pirata tuvo la suerte de encontrar un tesoro como tú <3",
    "Te mostraría lo mas hermoso de este mundo pero seguramente ya te viste en un espejo...<3",
    "Me gustan los colores azules, me gustan los colores rojos, pero lo que más me gusta es el café de tus ojos..<3",
    "Si la luna fuera mía te la regalaría... Pero como no es mía, solo me queda decirte que eres el amor de mi vida..<3",
    "Vivo enamorado de sol, vivo enamorado de la luna, enamorado de una niña que es más bonita que ninguna <3",
    "Si tus ojos fueran luceros y tus labios el mar, que paisaje tan bonito para poder admirar... <3",
    "Ni en mil maneras de morir muestran las miles de maneras que yo me muero por ti <3.",
    "El mar el inmenso y el desierto infinito, pero estar contigo es lo más bonito..<3",
    "Que te parece si tenemos un amor igual de grande que el cielo y tan brillante como las estrellas... <3",
    "Tus ojos podrían iluminar un universo entero, tu nivel de belleza no cabría en un verso y daría la vida por robarte un respetuoso beso <3...",
    "La vida es horrible por qué si fuera hermosa se llamaría como tú <3",
  ];
  return (
    <>
      <div className="overlay"></div>
      <div className="stars" aria-hidden="true"></div>
      <div className="starts2" aria-hidden="true"></div>
      <div className="stars3" aria-hidden="true"></div>
      <main className="container">
        <div className="row">
          <div className="align-content-center">
            <h1>Frases de amor o algo así para dedicarle a una persona xd</h1>
            {frases && frases.length > 0 ? (
              frases.map((frases) => (
                <>
                  <p>"{frases}"</p>
                </>
              ))
            ) : (
              <p>No hay contenido que mostar</p>
            )}
          </div>
        </div>
      </main>
    </>
  );
}
